import { Container } from '@mui/material'
import React from 'react'
import { SectionSubHeading } from '../../Styles/style'
import ethscan from "../../../assets/images/eth.png"
import metamask from "../../../assets/images/metamask.png"
import uniswap from "../../../assets/images/paxos.png"
import dextools from "../../../assets/images/dextools.png"
import up from "../../../assets/images/border.png"
import styled from '@emotion/styled'
const JOiningLinks = styled.img`
/* width: 100%;
  max-width: ${(props) => (props.mw ? props.mw : "180PX")}; */
  margin-right: 70px;
  @media screen and (max-width:599px){
      /* margin-right: 0px; */
   /* max-width: ${(props) => (props.mw ? props.mw : "140PX")}; */

 }
`

const SectionMainWrapper = styled.div`
/* min-height: calc(100vh - 75px); */
/* min-height: 100vh; */
display: flex;
align-items: center;
background-image: url(${up});
background-repeat: no-repeat;
background-position: center bottom;
    background-size: cover;
    background-color: ${(props) => (props.bgColor ? props.bgColor : "")};
    padding: ${(props) => (props.p ? props.p : "0")};
    margin: ${(props) => (props.m ? props.m : "0")};
  text-align: ${(props) => (props.align ? props.align : "center")};
  border-top:${(props) => (props.bt ? props.bt : "")};
  @media screen and (max-width: 1299px) {
    /* background-size: 40%; */
    padding: ${(props) => (props.p799 ? props.p799 : "30px 0")};
  }
  @media screen and (max-width: 799px) {
    /* background-size: 60%; */
    padding: ${(props) => (props.p799 ? props.p799 : "30px 0")};
  }
  @media screen and (max-width: 599px) {
    /* background-size: 100%; */
    padding: ${(props) => (props.p599 ? props.p599 : "30px 0")};
  }
`;
const S2_Links = () => {
  return (
    <div>
        <SectionMainWrapper  p599="0rem 0 10rem" p799="0rem 0 10rem" p="0rem 0 10rem" id="links">
            <Container maxWidth="xl">
            <a href="#"
              data-aos="zoom-in-up" 
              data-aos-easing="ease-in"
              data-aos-delay="400"
              data-aos-duration="500"
              data-aos-offset="0">
              <JOiningLinks src={ethscan} />
            </a>
            <a href="#"    data-aos="zoom-in-up" 
              data-aos-easing="ease-in"
              data-aos-delay="500"
              data-aos-duration="500"
              data-aos-offset="0">
              <JOiningLinks src={metamask} />
            </a>
            <a href="#"    data-aos="zoom-in-up" 
              data-aos-easing="ease-in"
              data-aos-delay="600"
              data-aos-duration="500"
              data-aos-offset="0">
              <JOiningLinks src={uniswap} />
            </a>
            <a href="#"    data-aos="zoom-in-up" 
              data-aos-easing="ease-in"
              data-aos-delay="700"
              data-aos-duration="500"
              data-aos-offset="0">
              <JOiningLinks src={dextools} />
            </a>
            </Container>
        </SectionMainWrapper>
    </div>
  )
}

export default S2_Links
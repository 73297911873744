import React from "react";
import { Container, Grid, useTheme } from "@mui/material";

import { P, SectionHeading, SectionSubHeading } from "../../Styles/style";

import { MainGrid } from "./styles";
import styled from "@emotion/styled";
import up from "../../../assets/images/narrative.png"
import happydog from "../../../assets/images/happydog.png"
import twitter from "../../../assets/images/twitter.png"
import medium from "../../../assets/images/medium.png"
import telegram from "../../../assets/images/telegram.png"
const MainWrapper = styled.div`
/* background-image: url(${up});
background-size: cover; */
display:flex;
align-items: center;
/* min-height: 100vh; */
padding: ${(props) => (props.p ? props.p : "0")};
  margin: ${(props) => (props.m ? props.m : "0")};
  text-align: ${(props) => (props.align ? props.align : "center")};

  @media screen and (max-width: 799px) {
    padding: ${(props) => (props.p799 ? props.p799 : "")};
  }
  @media screen and (max-width: 599px) {
    padding: ${(props) => (props.p599 ? props.p599 : "30px 0")};
  }
`

const BuyNow = styled.a`
text-decoration: none;
/* background-color: ${(props) => (props.bgColor ? props.bgColor : props.theme.textPrimary)}; */
/* color: ${(props) => (props.Color ? props.Color : props.theme.textSecondary)}; */
border :1px solid #D69A00;
padding: 10px 20px 2px;
`
const TwiButton = styled.div`
display: flex;
align-items: cneter;
gap: 10px;
padding: 0px 0 0;
@media screen and (max-width:899px){
  padding: 0px 0 20PX;
}
@media screen and (max-width:599px){
  padding: 0px 0 20PX;
   display: grid;
}
`
const GridsWrap = styled(Grid)`
  
  `
const Happydogimg = styled.img`
width: 100%;
height: auto;
max-width: 600px;
`
const S4_Narrative = () => {
  const theme = useTheme()

  return (

    <div >

      <MainWrapper p599="14rem 0px" p="14rem 0" id="community">
        <Container maxWidth="xl">
          <MainGrid container spacing={0}>
            <GridsWrap item xs={12} md={6}>
              <SectionSubHeading align="left" p399="" p="0px 0" p599="" maxWidth="700px"  data-aos="flip-up"
                data-aos-easing="ease-in"
                data-aos-delay="400"
                data-aos-duration="500"
                data-aos-offset="0">Community </SectionSubHeading>
              <P   data-aos="fade-zoom-in" 
              data-aos-easing="ease-in"
              data-aos-delay="500"
              data-aos-duration="500"
              data-aos-offset="0" >We believe in the power of unity and collaboration, and that's why we've built this space for like-minded individuals who share our vision of financial empowerment through digital assets. Whether you're a seasoned investor or just starting your journey in the world of cryptocurrencies, you're invited to join our passionate community of $Gold enthusiasts. Here, you can engage in lively discussions, share insights, and stay updated on the latest developments in the crypto world. Our team and fellow community members are here to answer your questions, offer support, and help you make the most of your $Gold investment. Together, we're on a mission to explore the limitless potential of digital gold. Join us today and be a part of the future of finance! </P>

              <TwiButton>
                <BuyNow data-aos="fade-up" 
              data-aos-easing="ease-in"
              data-aos-delay="400"
              data-aos-duration="500"
              data-aos-offset="0" href="https://t.me/goldinugold"><img src={telegram} /></BuyNow>
                <BuyNow data-aos="fade-up" 
              data-aos-easing="ease-in"
              data-aos-delay="500"
              data-aos-duration="500"
              data-aos-offset="0"><img src={twitter} /></BuyNow>
                {/* <BuyNow data-aos="fade-up" 
              data-aos-easing="ease-in"
              data-aos-delay="600"
              data-aos-duration="500"
              data-aos-offset="0"><img src={medium} /></BuyNow> */}
              </TwiButton>
            </GridsWrap>
            <Grid item xs={12} md={6} data-aos="fade-zoom-in" 
              data-aos-easing="ease-in"
              data-aos-delay="700"
              data-aos-duration="500"
              data-aos-offset="0" 
            >
              <Happydogimg src={happydog} />
            </Grid>

          </MainGrid>
        </Container>
      </MainWrapper>
    </div>
  );
};

export default S4_Narrative;

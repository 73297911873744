import styled from '@emotion/styled'
import { Container, Grid, useTheme } from '@mui/material'
import React from 'react'
import { Heading, P, SectionHeading, SectionSmallHeading, SectionSubHeading, SectionWrapper } from '../../Styles/style'
import one from "../../../assets/images/one.png"
import two from "../../../assets/images/two.png"
import three from "../../../assets/images/three.png"
import four from "../../../assets/images/four.png"

const DIv = styled.div`
  position: relative;
`
const Value = styled.p`
color: #fff;
position: absolute;
    /* top: 29px; */
    left: 70px;
    @media screen and (max-width:1199px){
        bottom: 29px;
        left: 70px;
        font-size: 12px;
    }
`
const Grids = styled(Grid)`
    /* display: flex; */
`

const BuyNow = styled.a`
text-decoration: none;
background-color: ${(props) => (props.bgColor ? props.bgColor : props.theme.textTertiary)};
color: ${(props) => (props.Color ? props.Color : props.theme.textPrimary)};
padding: 12px 20px;
border-radius: 5px;
margin-right: 15px;
display: flex;
align-items: center;

`
const Bdr = styled.div`
border-left:1px solid #fff;
height: 100px;
text-align: center;
color: #fff;
width: 1px;
@media screen and (max-width:899px){
    border-left:none;
    height: 0px;
    /* display: none; */
    padding: 20px 0;
}
 `
const GridiMG = styled(Grid)`
    text-align: left;
 `
const ImgRefl = styled.img`
 width: 100%;
 height: auto;
 
 max-width: ${(props) => (props.mw ? props.mw : "600px")};
 `
const S6_Tokenomics = () => {
    const theme = useTheme()
    return (
        <div>
            <SectionWrapper p="4rem 0" id="goldreflection">
                <Container maxWidth="xl" >
                    <SectionSubHeading  data-aos="fade-up"
                            data-aos-anchor-placement="center-bottom" data-aos-delay="500"
                            data-aos-duration="800">
                        How To Buy</SectionSubHeading>
                    <P align="center"  data-aos="fade-up"
                            data-aos-anchor-placement="center-bottom" data-aos-delay="500"
                            data-aos-duration="800">Anyone can invest and earn a passive income with $GOLD.</P>
                    <Grid container >
                        <Grid item xs={12} md={6} lg={3} data-aos="fade-up"
                            data-aos-anchor-placement="center-bottom" data-aos-delay="500"
                            data-aos-duration="800">
                            <ImgRefl src={one} />
                            <SectionSmallHeading>Set Up Your Wallet</SectionSmallHeading>
                            <P  maxWidth="70%">Create a Metamask/Defi Wallet account and top up your ETH balance through rthereum miannet chain.</P>
                        </Grid>
                        <GridiMG item xs={12} md={6} lg={3} data-aos="fade-up"
                            data-aos-anchor-placement="center-bottom" data-aos-delay="600"
                            data-aos-duration="800">
                            <ImgRefl src={two} />
                            <SectionSmallHeading>Access Uniswap</SectionSmallHeading>
                            <P  maxWidth="70%">Open uniswap.org and connect your wallet safely</P>


                        </GridiMG>
                        <GridiMG item xs={12} md={6} lg={3} data-aos="fade-up"
                            data-aos-anchor-placement="center-bottom" data-aos-delay="700"
                            data-aos-duration="800">
                            <ImgRefl src={three} />
                            <SectionSmallHeading>$Gold Contract</SectionSmallHeading>
                            <P maxWidth="70%">Paste our official contract and import.</P>


                        </GridiMG>
                        <GridiMG item xs={12} md={6} lg={3} data-aos="fade-up"
                            data-aos-anchor-placement="center-bottom" data-aos-delay="800"
                            data-aos-duration="800">
                            <ImgRefl src={four} mw="50px" />
                            {/* <SectionSubHeading fs="70px" p="10px 0 0" m="0" align="left">4</SectionSubHeading> */}
                            <SectionSmallHeading>Swap $Gold</SectionSmallHeading>
                            <P  maxWidth="70%">Start buy $gold and hold to earn rewards passively.</P>


                        </GridiMG>


                    </Grid>
                </Container>
            </SectionWrapper>
        </div>
    )
}

export default S6_Tokenomics
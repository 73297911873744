import styled from "@emotion/styled"

const WrAPPER = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
@media screen and (max-width:666px){
  display: inline-block;
}
`
const SocialHeading = styled.span`
color: #fff;
font-size: 20px;
`
const SocialMedia = styled.div`
display: flex;
padding: 30px 0;
align-items: center;
gap: 20px;
@media screen and (max-width:599px){
  gap: 2px;

}
`
const SocialLinks = styled.a`
text-decoration:NONE;
border: 1px solid #000;
padding: 7px 20px 2px;
@media screen and (max-width:599px){
  padding: 7px 10px 2px;

}
`
const SocialImage = styled.img`
width:100% ;
height: auto;
max-width: 120px;
/* padding-right:25px; */
`
const ParaDesktop = styled.div`
@media screen and (max-width:670px){
  display: none;
}
`
const MobPara = styled.div`
display: none;
@media screen and (max-width:670px){
  display: flex;
}
`
export { WrAPPER, SocialHeading, SocialMedia, SocialLinks, SocialImage, ParaDesktop, MobPara, }
import { Container, Grid } from "@mui/material";
import React from "react";
import { Heading, P, SectionHeading, SectionWrapper } from "../../Styles/style";
import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import goldlogo from "../../../assets/images/logo.png"
import Twitter from "../../../assets/images/btwitter.png"
import Telegram from "../../../assets/images/btelegram.png"
import Medium from "../../../assets/images/bmedium.png"
import footerdog from "../../../assets/images/footerdog.png"

import { SocialImage, SocialLinks, SocialMedia } from "./styles";
const ManImg = styled.img`
width: 100%;
height: auto;
max-width: 500px;
`
const GridWrapper = styled(Grid)`
display: flex;
align-items: center;
/* min-height: calc(50vh - 0px); */
`
const ScrollDiv = styled.div`
text-decoration: none;
text-align-last: center;
display: flow-root;
`
const FooterImg = styled.img`
width: 100%;
height: auto;
max-width: 300px;

`
const Section8_Team = () => {
  const theme = useTheme()
  return (

    <div>
      <SectionWrapper p="4rem 0 0" >
        <Container maxWidth="xl">
          <GridWrapper container>
            <Grid item xs={12} md={4}  data-aos="fade-zoom-in" 
              data-aos-easing="ease-in"
              data-aos-delay="300"
              data-aos-duration="500"
              data-aos-offset="0"  >
              <FooterImg src={footerdog} />
            </Grid>

            <Grid item xs={12} md={8}  >
              <Heading align="left" p="20px 0" m="0" color={theme.textSecondary} data-aos="flip-up"
                data-aos-easing="ease-in"
                data-aos-delay="400"
                data-aos-duration="500"
                data-aos-offset="0" >
                If You Have Any Questions
              </Heading>
              <P p="0" m="0" color={theme.textSecondary}  data-aos="fade-zoom-in" 
              data-aos-easing="ease-in"
              data-aos-delay="500"
              data-aos-duration="500"
              data-aos-offset="0">Come hang out with us on Telegram where the team and our lovely community <br />can help you find answers!</P>
              <SocialMedia>
                {/* <SocialLinks href="#/" target="blank" data-aos="fade-up" 
              data-aos-easing="ease-in"
              data-aos-delay="400"
              data-aos-duration="500"
              data-aos-offset="0"><SocialImage src={Medium} /></SocialLinks> */}
                <SocialLinks href="https://twitter.com/goldinugold" target="blank" data-aos="fade-up" 
              data-aos-easing="ease-in"
              data-aos-delay="500"
              data-aos-duration="500"
              data-aos-offset="0"><SocialImage src={Twitter} /></SocialLinks>
                <SocialLinks href="https://t.me/goldinugold" target="blank"data-aos="fade-up" 
              data-aos-easing="ease-in"
              data-aos-delay="600"
              data-aos-duration="500"
              data-aos-offset="0"><SocialImage src={Telegram} /></SocialLinks>
              </SocialMedia>
            </Grid>

          </GridWrapper>

        </Container>
      </SectionWrapper>
    </div>
  )
};

export default Section8_Team;

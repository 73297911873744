import React, { useEffect } from "react";

import Aos from "aos";
import "aos/dist/aos.css";

import Menu from "../../Components/Menu";

import Section1_Header from "../S1_Header";
import Section3_Tokenomics from "../S3_About";
import S6_Tokenomics from "../S5_Howtobuy";
import Section8_Team from "../S7_Footer";
import { PageWrapper } from "../../Styles/style";

import styled from "@emotion/styled";
import S2_Links from "../S2_Links";
import S4_Narrative from "../S4_Community";

const Ca = styled.div`
  padding: 10px 0;
  text-align: center;
font-size: 18px;
  font-family: "P-SB";
 
  background: ${(props) => props.theme.Gradient};
`
const Co = styled.div`
  padding: 20px 0;
  text-align: center;
font-size: 18px;
  font-family: "P-L";
 color: #fff;
`
const SectionSubWrapper = styled.div`
background: ${(props) => props.theme.Gradient};
`
const MainPage = (props) => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
    window.addEventListener("load", function () {
      Aos.init();
    });
  }, []);
  
  return (
    <>
      <PageWrapper>
        <div   data-aos="fade-up"
    data-aos-anchor-placement="center-bottom"  data-aos-delay="600"
    data-aos-duration="1500">
      <Ca data-aos="fade-zoom-in" 
              data-aos-easing="ease-in"
              data-aos-delay="400"
              data-aos-duration="500"
              data-aos-offset="0" >
        Contract Address: &nbsp;TBA
      </Ca>
          <Menu/>
          <Section1_Header />
        </div>
          <S2_Links/>
          <Section3_Tokenomics />
          <S4_Narrative/>
          <S6_Tokenomics />
          <SectionSubWrapper>
            <Section8_Team />
          </SectionSubWrapper>
      <Co data-aos="fade-zoom-in" 
              data-aos-easing="ease-in"
              data-aos-delay="700"
              data-aos-duration="500"
              data-aos-offset="0" >
      Copyright © 2023 thecontinental.gold. All Rights Reserved
      </Co>
      </PageWrapper>
    </>
  );
};

export default MainPage;

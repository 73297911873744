import React from "react";
import { Container, Grid, useTheme } from "@mui/material";

import { P, SectionHeading, SectionSubHeading } from "../../Styles/style";

import { MainGrid } from "./styles";
import styled from "@emotion/styled";
import up from "../../../assets/images/secondImg.png"

const MainWrapper = styled.div`
/* background-image: url(${up});
background-size: cover; */
display:flex;
align-items: center;
justify-content: center;
/* min-height: 100vh; */
padding: ${(props) => (props.p ? props.p : "0")};
  margin: ${(props) => (props.m ? props.m : "0")};
  text-align: ${(props) => (props.align ? props.align : "center")};

  @media screen and (max-width: 799px) {
    padding: ${(props) => (props.p799 ? props.p799 : "")};
  }
  @media screen and (max-width: 599px) {
    padding: ${(props) => (props.p599 ? props.p599 : "30px 0")};
  }
`
const GridsWrap = styled(Grid)`
`
const Arbspoan = styled.span`  
text-shadow: 0px 0px 20px #ffd260a7;
background: linear-gradient(to right,#D69A00,#F2B926,#FFCE50,#F4BB28,#D69A00);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
color: ${(props) => (props.color ? props.color : props.theme.bgSecondary)};
  font-size: ${(props) => props.fs ? props.fs : "calc(45px + (26 - 22) * ((100vw - 146px) / (1600 - 250)))"};
font-family: "C-BR";
  width: ${(props) => (props.maxWidth ? props.maxWidth : "100%")};
  padding: ${(props) => (props.p ? props.p : "10px 0")};
  text-align: ${(props) => (props.align ? props.align : "")};
  font-weight: 200;
  margin: ${(props) => (props.m ? props.m : "")};

  @media screen and (max-width: 972px) {
    font-size: ${(props) => props.fs ? props.fs : "calc(40px + (26 - 20) * ((100vw - 16px) / (1600 - 250)))"};
  }
  @media screen and (max-width: 419px) {
    font-size: ${(props) => props.fs ? props.fs : "calc(40px + (26 - 10) * ((100vw - 26px) / (1600 - 250)))"};
  }
`
const BuyNow = styled.a`
text-decoration: none;
background: ${(props) => (props.bgColor ? props.bgColor : props.theme.textSecondary)};
color: ${(props) => (props.Color ? props.Color : props.theme.bgSecondary)};
border: 1px solid #E0A50F;
padding: 8px 30px;
font-family: "P-SB";
`
const TwiButton = styled.div`
display: flex;
align-items: center;
justify-content: center;
gap: 10px;
padding: 40px 0 0;
`
const Index = () => {
  const theme = useTheme()

  return (
    <div >
      <MainWrapper align="center"   p="5rem 0" id="about"
        data-aos="fade-up"
        // data-aos-anchor-placement="center-bottom" 
        data-aos-delay="400"
        data-aos-duration="500"
      >
        <Container maxWidth="xl">
          <MainGrid container spacing={0}>
            <GridsWrap item xs={12} md={8} lg={6}
              data-aos="fade-up"
              //  data-aos-anchor-placement="center-bottom"
              data-aos-delay="400"
              data-aos-duration="500">
              <SectionSubHeading align="center">About $Gold</SectionSubHeading>
              <P align="center" m="0">The dollar is failing and all the elites are moving their assets to gold. Just like many times throughout history, whenever we were on the brink of war, the dollar fails and the elites convert their assets to gold! History is repeating itself once again as the central banks are once again trying to bail out the many failing banks causing interest rates to increase and the US dollar to fail.
                <br />
                <br />
                However, Bitcoin has been established as the new digital gold and its increased confidence and value in other currencies as well like ethereum, xrp, and our token of choice, Paxos Gold!
                <br />
                <br />

                We’ve specifically chosen paxos because it embodied the best of both worlds, it’s a digital asset that is backed by gold! Yes, gold is exploding right now but it’s the only hard asset that still stands today which possesses centuries of confidence and trust in all places of the world.</P>
            </GridsWrap>

          </MainGrid>

        </Container>
      </MainWrapper>
      <div  >
      <MainWrapper align="center"   p="5rem 0" id="tokenomics"
        data-aos="fade-up"
        // data-aos-anchor-placement="center-bottom" 
        data-aos-delay="400"
        data-aos-duration="500"
      >
        <Container maxWidth="xl">
          <MainGrid container spacing={0}>
            <GridsWrap item xs={12} md={8} lg={5}
              data-aos="fade-up"
              //  data-aos-anchor-placement="center-bottom"
              data-aos-delay="400"
              data-aos-duration="500">
              <SectionSubHeading align="center">Explore Tokenomics</SectionSubHeading>
              <P align="center" m="0">Through every $GOLD transaction a 5% Tax fee is taken, 3% of it going
                directly back to holders in Pax Gold($PAXG) Rewards </P>
              <br />
              <br />
              <Grid container>
                <Grid item xs={4}>
                  <SectionSubHeading p="0" m="0">TBA</SectionSubHeading>
                  <P align="center" p="0" m="0">Supply</P>
                </Grid>
                <Grid item xs={4}>
                  <SectionSubHeading p="0" m="0">TBA</SectionSubHeading>
                  <P align="center" p="0" m="0">Buy/Sell Tax</P>
                </Grid>
                <Grid item xs={4}>
                  <SectionSubHeading p="0" m="0">TBA</SectionSubHeading>
                  <P align="center" p="0" m="0">Max Wallet</P>
                </Grid>
              </Grid>

            </GridsWrap>

          </MainGrid>

        </Container>
      </MainWrapper>
      </div>
      
      <div  >

      <MainWrapper align="center"   p="5rem 0" id="secure&safe"
        data-aos="fade-up"
        // data-aos-anchor-placement="center-bottom" 
        data-aos-delay="400"
        data-aos-duration="500"
      >
        <Container maxWidth="xl">
          <MainGrid container spacing={0}>
            <GridsWrap item xs={12} md={8} lg={5}
              data-aos="fade-up"
              //  data-aos-anchor-placement="center-bottom"
              data-aos-delay="400"
              data-aos-duration="500">
              <SectionSubHeading align="center">Secure & Safe</SectionSubHeading>
              <P align="center" m="0">The ownership of the contract has been renounced and the Liquidity Pool tokens have been locked.
                That means next to zero risk to our users and community from potential bad actors. </P>
              <TwiButton>

                <BuyNow>
                  Liquidity Locked
                </BuyNow>
                <BuyNow>
                  Renounce
                </BuyNow>
              </TwiButton>

            </GridsWrap>

          </MainGrid>

        </Container>
      </MainWrapper>
      </div>
      
    </div>
  );
};

export default Index;

import React from "react";

import { P, SectionHeading, SectionMainWrapper } from "../../Styles/style";

import styled from "@emotion/styled";
import { Container, Grid, useTheme } from "@mui/material";
import dog from "../../../assets/images/babydog.png"
import up from "../../../assets/images/shadoew.png"

const GridHEading = styled(Grid)`
padding: 50px 0;
display: flex;
align-items: center;
@media screen and (max-width:599px){
  padding: 0px 0;
}
`;
const GridImage = styled(Grid)`
padding: 50px 0;
text-align: right;
/* background-image: url(${up});
background-repeat: no-repeat;
background-position: top;
    background-size: cover; */

@media screen and (max-width:599px){
  padding: 0px 0;
}
`;
const GridtEXT = styled(Grid)`
padding: 50px 0;
text-align: left;

@media screen and (max-width:599px){
  padding: 0px 0;
}
`;

const BuyNow = styled.a`
text-decoration: none;
background: ${(props) => (props.bgColor ? props.bgColor : props.theme.Gradient)};
color: ${(props) => (props.Color ? props.Color : props.theme.textSecondary)};
border: 1px solid #E0A50F;
padding: 8px 30px;
font-family: "P-SB";
`
const Arbspoan = styled.h1`
color: ${(props) => (props.color ? props.color : props.theme.bgSecondary)};
  font-size: ${(props) => props.fs ? props.fs: "calc(85px + (26 - 22) * ((100vw - 146px) / (1600 - 250)))"};
  font-family: "P-B";
  width: ${(props) => (props.maxWidth ? props.maxWidth : "100%")};
  padding: ${(props) => (props.p ? props.p : "0px 0")};
  text-align: ${(props) => (props.align ? props.align : "")};
  margin: ${(props) => (props.m ? props.m : "0")};

  @media screen and (max-width: 972px) {
    font-size: ${(props) => props.fs ? props.fs: "calc(60px + (26 - 20) * ((100vw - 16px) / (1600 - 250)))"};
  }
  @media screen and (max-width: 419px) {
    font-size: ${(props) => props.fs ? props.fs: "calc(60px + (26 - 10) * ((100vw - 26px) / (1600 - 250)))"};
  }
`
const TwiButton = styled.div`
display: flex;
align-items: cneter;
gap: 10px;
padding: 40px 0 0;
`
const DogBg = styled.div`
background-image: url(${up});
background-repeat: no-repeat;
background-position: center;
    background-size: 30%;
`
const ImgDog = styled.img`
width: 100%;
height: auto;
max-width: 400px;
`
const Arbspoan1 = styled.span`
color: ${(props) => (props.color ? props.color : props.theme.bgSecondary)};
  font-size: ${(props) => props.fs ? props.fs: "calc(85px + (26 - 22) * ((100vw - 146px) / (1600 - 250)))"};
  font-family: "P-B";
  width: ${(props) => (props.maxWidth ? props.maxWidth : "100%")};
  padding: ${(props) => (props.p ? props.p : "0px 0")};
  text-align: ${(props) => (props.align ? props.align : "")};
  margin: ${(props) => (props.m ? props.m : "0")};

  @media screen and (max-width: 972px) {
    font-size: ${(props) => props.fs ? props.fs: "calc(60px + (26 - 20) * ((100vw - 16px) / (1600 - 250)))"};
  }
  @media screen and (max-width: 419px) {
    font-size: ${(props) => props.fs ? props.fs: "calc(60px + (26 - 10) * ((100vw - 26px) / (1600 - 250)))"};
  }
`
const Section1_Header = () => {
  const theme = useTheme()
  return (
    <>
    <SectionMainWrapper p="0rem 0" id="home" >
      <Container>
        <GridHEading container >
          <GridtEXT xs={12} md={6}>
            <SectionHeading
              p="30px 0 0"
              fs="calc(85px + (26 - 22) * ((100vw - 146px) / (1600 - 250)))"
              m="0"
              align=""
              data-aos="flip-up"
              data-aos-easing="ease-in"
              data-aos-delay="400"
              data-aos-duration="600"
              data-aos-offset="0"
              style={{lineHeight:'0.9'}}
            > 
            BUY<Arbspoan1>$Gold,</Arbspoan1>
            <br></br>
            And Earn
            <br></br>
            Real<Arbspoan1>Gold</Arbspoan1>
            </SectionHeading>
           
            <P p="0" m="0">The Supreme Form Of Payment Used By The Elites.</P>
          <TwiButton>

          <BuyNow>Buy Now</BuyNow>
          <BuyNow 
          bgColor={theme.textSecondary}
          Color={theme.bgSecondary}
          brColor="1px solid red"
          >View Chart</BuyNow>
          </TwiButton>
          </GridtEXT>
          <GridImage xs={12} md={6}>
          {/* <DogBg> */}

            <ImgDog src={dog}/>
          {/* </DogBg> */}
          
          </GridImage>
        
        </GridHEading>
      </Container>
    </SectionMainWrapper>
    
     </>
  );
};

export default Section1_Header;
